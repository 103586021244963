import React from "react"
import { motion } from "framer-motion"
import Navigation from "./Navigation"
import { Link } from "gatsby"
export default function Layout({ children }) {
  return (
    <div>
      <div className="w-full items-center content-center relative max-w-screen-xl mx-auto px-5">
        <Navigation />
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.3,
          }}
        >
          {children}
        </motion.main>
      </div>
      <div className="border-t mt-16 md:mt-28">
        <div className="w-full items-center content-center relative max-w-screen-xl mx-auto">
          <footer className="text-left md:grid grid-cols-3 auto-rows-fr py-10 text-gray-700">
            <div className="p-5 md:border-l">
              {/* <Link className="block" to="/antiques">
                Antiques
              </Link> */}
              <Link className="block" to="/interior-design">
                Interior design
              </Link>

              {/* <Link className="block" to="/gallery">
                The gallery
              </Link> */}
              <Link className="block" to="/about">
                About
              </Link>
              <Link className="block" to="/contact">
                Contact
              </Link>
            </div>
            <div className="p-5 border-l">
              <span className="block">Marie-Louise Sjögren</span>
              <span className="block mb-3">
                Runebergsgatan 12, 114 29 Stockholm
              </span>
            </div>
            <div className="p-5 border-l">
              <span className="block">Contact:</span>
              <span className="block">marie-louise@marielouisesjogren.com</span>
              <a
                href="https://instagram.com/marielouise_sjogren_design"
                className="block mb-3 hover:line-through"
              >
                Instagram
              </a>

              <span className="block">© Marie-Louise Sjögren 2021 </span>
              <span className="block">
                <a
                  className="hover:line-through cursor-pointer"
                  href="https://studiopoi.com"
                >
                  Made by Studio Poi
                </a>
              </span>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}
