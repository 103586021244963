import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Shortcuts = ({ link1, link2 }) => {
  const shortcuts = useStaticQuery(
    graphql`
      query {
        shortcuts: allSanityShortcuts {
          nodes {
            short
            title
            link
            image {
              asset {
                gatsbyImageData(width: 700, height: 400)
              }
            }
          }
        }
      }
    `
  )

  return (
    <div className="pagebuilder mt-20">
      {shortcuts.shortcuts.nodes.map(item =>
        item.link === link1 ? (
          <Link
            key={item.link}
            className="grid md:grid-cols-2 md:gap-20 mb-10 border-l pl-5"
            to={item.link}
          >
            <div>
              <h3 className="h2">{item.title}</h3>
              <p>{item.short}</p>
              <p className="button">See more</p>
            </div>
            <div className="row-start-1 md:row-auto">
              <GatsbyImage
                image={item.image.asset.gatsbyImageData}
                alt={item.title}
              />
            </div>
          </Link>
        ) : (
          ``
        )
      )}
    </div>
  )
}

export default Shortcuts
