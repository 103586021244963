import { graphql, Link } from "gatsby"
import React from "react"
import Seo from "../components/utils/Seo"
import Layout from "../components/Layout"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import Shortcuts from "../components/Shortcuts"

import { BiChevronRight as Icon } from "react-icons/bi"

export const query = graphql`
  query {
    page: allSanityHomepage {
      nodes {
        highlight
        image {
          alt
          asset {
            url
            landscape: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        fromthemlscollection {
          landscapeimage {
            asset {
              gatsbyImageData(width: 700, height: 500)
            }
          }
          title
          slug {
            current
          }
        }
        related {
          link
        }
        meta {
          title
          description
        }
      }
    }
    antiques: allSanityAntiques(limit: 8) {
      nodes {
        title
        id
        short
        slug {
          current
        }
        landscapeimage {
          alt
          asset {
            gatsbyImageData(width: 800, height: 800)
          }
        }
      }
    }
  }
`

const Home = props => {
  const page = props.data.page.nodes[0]
  const antiques = props.data.antiques.nodes

  return (
    <Layout>
      <Seo
        title={page.meta && page.meta.title ? page.meta.title : ""}
        description={
          page.meta && page.meta.description ? page.meta.description : ""
        }
      />
      <GatsbyImage
        className="w-full h-full"
        image={page.image.asset.landscape}
        alt={page.image.alt}
      />

      <div className="m-auto w-4/5">
        <h1 className="highlight">{page.highlight}</h1>
      </div>

      {/* <div className="md:grid md:grid-cols-2 gap-5 my-5">
        {page.fromthemlscollection.map(item => (
          <Link
            to={`/mls-collection/${item.slug.current}`}
            className="relative flex justify-items-center m-auto my-5"
          >
            <GatsbyImage
              image={item.landscapeimage.asset.gatsbyImageData}
              alt={item.title}
            />
            <h3 className="uppercase text-center font-normal absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white py-6 px-10 inline-block">
              MLS collection
              <span className="block font-light">{item.title}</span>
            </h3>
          </Link>
        ))}
      </div> */}

      {/* <div>
        <h2 className="mt-10 mb-5">Antiques</h2>
        <div className="md:grid grid-cols-4 gap-5">
          {antiques.map(item => (
            <Link
              className="mb-7 block"
              key={item.slug.current}
              to={`/antiques/${item.slug.current}`}
            >
              <GatsbyImage
                image={item.landscapeimage.asset.gatsbyImageData}
                alt={item.landscapeimage.alt}
              />
              <h3 className="h3 mt-5 border-b pb-3 w-ful">{item.title}</h3>
              <p className="mt-1">{item.short}</p>
              <p className="mt-1 hover:underline text-gray-500">
                View object
                <Icon className="inline-block " />
              </p>
            </Link>
          ))}
        </div>
        <div className="md:text-center">
          <Link to="/antiques/" className="m-auto button">
            View all Antiques
          </Link>
        </div>
      </div> */}

      <Shortcuts link1={page.related[0].link} link2={page.related[1].link} />
    </Layout>
  )
}

export default Home
